import React from "react";

import Hero from "./assets/hero.png";
import HeroMobile from "./assets/mobile-hero.png";
import Markdown from "../Common/Markdown";
import {
  HeroContainer,
  HeroPicture,
  HeroPictureMobile,
  InternalWrapper,
  HeroTextDiv,
  Title,
  PhoneAndParagraph,
  Paragraph,
} from "./townshipHero.styles";

const TownshipHero = (props) => {
  return (
    <HeroContainer>
      <HeroPicture>
        <source srcSet={Hero} type="image/avif" />
        <img src={Hero} alt="Smiling Life Homecare Nurse" />
      </HeroPicture>
      <HeroPictureMobile>
        <source srcSet={HeroMobile} type="image/avif" />
        <img src={HeroMobile} alt="Smiling Life Homecare Nurse" />
      </HeroPictureMobile>
      <InternalWrapper data-aos="fade">
        <HeroTextDiv>
          <Title>{props.title}</Title>
          <PhoneAndParagraph>
            <Paragraph>
              <Markdown text={props.subtitle} textColor="white" />
            </Paragraph>
          </PhoneAndParagraph>
        </HeroTextDiv>
      </InternalWrapper>
    </HeroContainer>
  );
};

export default TownshipHero;
