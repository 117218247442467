import React from "react";
import { graphql } from "gatsby";

import seoImage from "../../static/seo/seo_image.png";

import Seoinfo from "./seoinfo/seoinfo";
import Layout from "./layout";
import transformToSlug from "./utils";
import ContactUs from "./ContactUs/ContactUs";
import TownshipDescription from "./TownshipDescription/TownshipDescription";
import OurCareServices from "./OurCareServices/OurCareServices";
import TownshipHero from "./TownshipHero/TownshipHero";

const TownshipTemplate = ({ data, pageContext }: any) => {
  const TownshipData = data?.cms?.locationsTownshipPage?.data?.attributes;
  const TownshipUrl = `/locations/${
    pageContext.county
  }/home-care-in-${transformToSlug(
    TownshipData.locations_township?.data?.attributes?.Township
  )}`;

  const handleDescription = () => {
    switch (TownshipUrl) {
      case "/locations/essex-county/home-care-in-livingston":
        return `Reliable home care services in Livingston with Life Home Care, a trusted caregiving agency offering personalized care to support your loved ones’ needs.`;
      case "/locations/bergen-county/home-care-in-glen-rock":
        return `Discover exceptional home care services in Glen Rock with Life Home Care. Providing personalized, high-quality care to meet your unique needs. Contact us today.`;
      case "/locations/somerset-county/home-care-in-somerset-county":
        return `Find reliable home care services in Somerset County with Life Home Care. Trusted caregiving agency offering personalized support to enhance your loved one's quality of life.`;
      default:
        return `Discover top-notch Life Homecare services in ${TownshipData.locations_township?.data?.attributes?.Township}. Get the care you deserve today!`;
    }
  };

  return (
    <main>
      <Layout>
        <Seoinfo
          siteName={`Home care Services in ${TownshipData.locations_township?.data?.attributes?.Township} | Life Home Care`}
          titlePage={`Home care Services in ${TownshipData.locations_township?.data?.attributes?.Township} | Life Home Care`}
          description={handleDescription()}
          keywords="Home, Care, HomeCare, Senior, Compassionate, Care, Home Care Agency, Home Care Agencies"
          type="website"
          url={TownshipUrl}
          imageFacebook={seoImage}
          canonical
        />
        <TownshipHero
          title={TownshipData.Hero_Title}
          subtitle={TownshipData.Hero_Subtitle}
        />
        <OurCareServices />
        <TownshipDescription
          title={TownshipData.Township_Description.Title}
          description={TownshipData.Township_Description.Description}
          image={TownshipData.Township_Description.Image}
        />
        <ContactUs />
      </Layout>
    </main>
  );
};

export const pageQuery = graphql`
  query($id: ID!) {
    cms {
      locationsTownshipPage(id: $id) {
        data {
          attributes {
            locations_township {
              data {
                attributes {
                  Township
                }
              }
            }
            Hero_Title
            Hero_Subtitle
            Township_Description {
              Title
              Description
              Image {
                ...Media
              }
            }
          }
        }
      }
    }
  }
`;

export default TownshipTemplate;
