import React, { useState, useEffect } from "react";

import {
  Container,
  ImageContainer,
  TextContainer,
  Title,
  Description,
  WhiteLine,
} from "./ourCareServices.styles";
import secondPanelImage from "./assets/second-panel.png";
import thirdPanelImage from "./assets/third-panel.png";
import MediaRender from "../Common/MediaRender";

const OurCareServices = () => {
  const [secondModuleHeightImage, setSecondModuleHeightImage] = useState("");
  const [thirdModuleHeightImage, setThirdModuleHeightImage] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const secondModule = document.getElementById("second-module");
      setSecondModuleHeightImage(secondModule.offsetHeight);
      const thirdModule = document.getElementById("third-module");
      setThirdModuleHeightImage(thirdModule.offsetHeight);
    };
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <Container>
        <ImageContainer height={secondModuleHeightImage}>
          <MediaRender mediaSource={secondPanelImage} />
        </ImageContainer>
        <TextContainer id="second-module">
          <Title>OUR HOME CARE SERVICES</Title>
          <WhiteLine />
          <Description>
            <strong>Hourly Care:</strong> Our caregivers are available for
            hourly visits to assist with daily activities, allowing clients to
            maintain their normal routines and age in place.
          </Description>
          <Description>
            <br />
            <strong>Respite Care:</strong> Need a break? We provide temporary
            support so you can recharge and focus on your own needs.
          </Description>
          <Description>
            <br />
            <strong>Live-In Care:</strong> A dedicated caregiver lives in your
            home, offering personalized support and companionship 24/7.
          </Description>
          <Description>
            <br />
            <strong>Overnight Care:</strong> Rest assured with a qualified
            caregiver present overnight to meet your nighttime needs.
          </Description>
          <Description>
            <br />
            <strong>Case Management:</strong> Our experienced case managers work
            with you and your family to develop a customized care plan.
          </Description>
        </TextContainer>
      </Container>
      <Container className="last">
        <TextContainer id="third-module">
          <Description>
            <strong>Medication Management:</strong> We ensure safe and accurate
            medication administration, especially for those with complex
            medication schedules.
          </Description>
          <Description>
            <br /> <strong>Companionship:</strong> Our caring aides provide
            companionship, social interaction, and assistance with daily tasks
            to combat loneliness and enhance well-being.
          </Description>
          <Description>
            <br />
            <strong>Discharge Coordination:</strong> We ensure a smooth
            transition from hospital or rehabilitation facilities back home,
            with a personalized care plan in place.
          </Description>
          <Description>
            <br />
            <strong>Transportation:</strong> Our caregivers can provide reliable
            transportation for doctor’s appointments, errands, and social
            outings.
          </Description>
          <Description>
            <br />
            <strong>Supplemental Care:</strong> We can help navigate
            supplemental insurance options to ensure you receive the care you
            need.
          </Description>
        </TextContainer>
        <ImageContainer height={thirdModuleHeightImage}>
          <MediaRender mediaSource={thirdPanelImage} />
        </ImageContainer>
      </Container>
    </>
  );
};

export default OurCareServices;
